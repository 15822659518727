export const SET_HUNTLOCATIONS = "SET_HUNTLOCATIONS";
export const SET_HUNTLOCATIONS_ERROR = "SET_HUNTLOCATIONS_ERROR";
export const SET_HUNTLOCATIONS_CURRENT_STATE = "SET_HUNTLOCATIONS_CURRENT_STATE";
export const SET_HUNTLOCATIONS_SELECTED_OBJECT = "SET_HUNTLOCATIONS_SELECTED_OBJECT";
export const SET_HUNTLOCATIONS_FILTER = "SET_HUNTLOCATIONS_FILTER";

export const SET_HUNTCITIES = "SET_HUNTCITIES";
export const SET_HUNTCITIES_ERROR = "SET_HUNTCITIES_ERROR";
export const SET_HUNTCITIES_CURRENT_STATE = "SET_HUNTCITIES_CURRENT_STATE";
export const SET_HUNTCITIES_SELECTED_OBJECT = "SET_HUNTCITIES_SELECTED_OBJECT";

export const SET_FORM_PROCESSING = "SET_FORM_PROCESSING";
export const SET_FORM_ERROR = "SET_FORM_ERROR";
export const SET_FORM_SUCCESS = "SET_FORM_SUCCESS";
export const CLEAR_FORM_ERROR = "CLEAR_FORM_ERROR";
export const CLEAR_FORM_SUCCESS = "CLEAR_FORM_SUCCESS";
export const SET_FORM_TEMP_OBJECT = "SET_FORM_TEMP_OBJECT";
export const CLEAR_FORM_TEMP_OBJECT = "CLEAR_FORM_TEMP_OBJECT";

export const SET_LOGIN = "SET_LOGIN";
export const SET_LOGOUT = "SET_LOGOUT";
export const SET_LOGIN_ERROR = "SET_LOGIN_ERROR";